import classnames from 'classnames'
import * as React from 'react'
import AlertMessage, { MessageType } from '$src/shared/components/AlertMessage'
import UserDashboard from './UserDashboard'
import ImageItemGrid from '../../components/ImageItemGrid'
import Ads from '$src/shared/components/Ads'
import { Text } from '$src/shared/content/text'
import * as css from '$src/shared/layout/Layout.css'
import CustomerNumberForm from '../../forms/CustomerNumberForm'
import FlashMessageFromRouter from '$src/shared/components/FlashMessageFromRouter'
import Layout, { SidebarAdType } from '$src/shared/layout/Layout'
import { Customer } from '../../types/Customer'
import bgImage from '$src/shared/img/bg_image.jpg'
import get from 'lodash/get'
import PromoCodeForm from '$src/user/forms/PromoCodeForm'
import SubTitle from '$src/shared/components/SubTitle'
import Title from '$src/shared/components/Title'
import { ContentType, withEntriesBySlug } from '$src/shared/content/withContentful'
import { WithViewer, withViewerContext } from '$src/shared/helpers/withViewer'
import { RichText } from '$src/shared/types/Contentful'

interface Props extends WithViewer {
  queryResult: { customer: Customer }
  content: {
    alertMessage: Array<{
      body: RichText
    }>
    infoMessage: Array<{
      body: RichText
    }>
  }
}

@withEntriesBySlug(
  ContentType.alertMessage,
  'alert-message-user-logged-in',
  'alertMessage'
)
@withEntriesBySlug(
  ContentType.alertMessage,
  'info-message-user-logged-in',
  'infoMessage'
)
class Dashboard extends React.Component<Props, any> {
  render() {
    const {
      viewer,
      queryResult: { customer },
      content: { alertMessage: alertMessageContent, infoMessage: infoMessageContent }
    } = this.props

    const customerNumber = get(customer, 'customerNumbers[0]')
    const alertMessageHtml =
      alertMessageContent.length > 0 && alertMessageContent[0].body.plain
        ? alertMessageContent[0].body.html
        : undefined
    const infoMessageHtml =
      infoMessageContent.length > 0 && infoMessageContent[0].body.plain
        ? infoMessageContent[0].body.html
        : undefined

    return (
      <>
        {alertMessageHtml && (
          <AlertMessage type={MessageType.alert} message={alertMessageHtml} />
        )}
        {infoMessageHtml && (
          <AlertMessage type={MessageType.info} message={infoMessageHtml} />
        )}
        <Layout>
          {!customerNumber && (
            <Layout main background={{ url: bgImage }}>
              <FlashMessageFromRouter />
              <div
                className={classnames(
                  css.centeredLayout,
                  css.constrained,
                  css.section
                )}>
                <SubTitle title={Text('ADD_INFO')} text={Text('ADD_INFO_INGRESS')} />
                <CustomerNumberForm
                  isAdmin={customer.isAdmin}
                  enabled={true}
                  customerNumber={customerNumber}
                  customerId={customer && customer.id}
                />
              </div>
            </Layout>
          )}
          <Layout
            main
            sidebars={{ topOffset: 104, adType: SidebarAdType.LOGGED_IN }}>
            {customerNumber && customer.firstName ? (
              <Title
                title={Text('TITLE_GREETING_WITH_NAME', customer.firstName)}
                text={
                  customer.isAdmin && customer.lastActivity
                    ? Text('LAST_ACTIVITY_WITH_DATE', customer.lastActivity)
                    : Text('user.GREETING_INGRESS')
                }
                withAds
              />
            ) : (
              <Title
                title={Text('TITLE_GREETING')}
                text={
                  customer.isAdmin &&
                  customer.lastActivity &&
                  customer.isImpersonated
                    ? Text('LAST_ACTIVITY_WITH_DATE', customer.lastActivity)
                    : undefined
                }
                withAds
              />
            )}
            {customerNumber && <FlashMessageFromRouter />}
            <UserDashboard
              disabled={!customerNumber}
              viewer={viewer}
              customer={customer}
            />
            <Ads
              ids={[
                'Aspa_siskirjautuneet_boksi1',
                'Aspa_siskirjautuneet_boksi2',
                'Aspa_siskirjautuneet_boksi3'
              ]}
            />
            <div className={classnames(css.section, css.constrained)}>
              <PromoCodeForm />
            </div>
            <ImageItemGrid showRestrictedLinks={!!customerNumber} />
          </Layout>
        </Layout>
      </>
    )
  }
}

export default withViewerContext(Dashboard)
