import gql from 'graphql-tag'

const getViewer = gql`
  query GetViewer {
    viewer {
      id
      email
      phoneNumber
    }
  }
`

export default getViewer
