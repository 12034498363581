import * as React from 'react'
import { Query } from 'react-apollo'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as BaseCarousel from '$component-library-dist/BaseCarousel-2.0.0.umd.js'
import * as ProductCard from '$component-library-dist/ProductCard-2.1.0.umd.js'
import * as CirclePagination from '$component-library-dist/CirclePagination-1.0.0.umd.js'
import * as LehtitarjouksetRibbonOverlay from '$component-library-dist/LehtitarjouksetRibbonOverlay-1.2.0.umd.js'

import { sendUserActionToGTM } from '$src/shared/helpers/dataLayer'
import getCustomer from '$src/user/queries/getCustomer'
import getOffers from '../queries/getOffers'
import { Identity } from '../types/Identity'
import SubTitle from '$src/shared/components/SubTitle'
import { Text } from '$src/shared/content/text'
import withWindowDimensions from '$src/shared/helpers/withWindowDimensions'
import { withViewerContext } from '$src/shared/helpers/withViewer'
import get from 'lodash/get'

import * as css from './offers.css'

// component-library theme id
const THEME_ID = 'alehdet_fi'

type Props = {
  title?: string
  className?: string
  viewer?: Identity
  dimensions: number[]
}

class Offers extends React.Component<Props, any> {
  state = {
    // Visible carousel page index (+-infinite)
    currentPage: 0,
    // Normalized value of currentPage (from 0 to number of items - 1)
    normalizedPage: 0
  }
  render() {
    const { viewer, dimensions, title = Text('BEST_DEALS') } = this.props
    const { currentPage, normalizedPage } = this.state
    const viewerId = get(viewer, 'id')

    const [width] = dimensions

    if (!viewerId) {
      return null
    }

    return (
      <Query query={getCustomer} variables={{ viewerId: viewer.id }}>
        {({ loading, error, data }) => {
          if (loading || error) {
            return null
          }
          if (!data.customer || !data.customer.id) {
            return null
          }

          const { customer } = data

          return (
            <Query query={getOffers} variables={{ viewerId: viewer.id }}>
              {({ loading, error, data }) => {
                if (loading || error) {
                  return null
                }
                if (data.customerOffers.length === 0) {
                  return null
                }

                const perPage = (width < 670 && 1) || (width < 1040 && 3) || 4
                const pageCount = Math.ceil(data.customerOffers.length / perPage)

                // Is the product slide/card visible.
                const isSlideVisible = (i: number) => {
                  if (perPage === 1) {
                    return i === normalizedPage
                  } else {
                    const slidePageIndex = Math.floor(i / perPage)
                    return slidePageIndex === Math.abs(currentPage % perPage)
                  }
                }

                return (
                  <div className={css.component}>
                    <SubTitle title={title} />
                    <BaseCarousel
                      theme={THEME_ID}
                      index={currentPage}
                      perPage={perPage}
                      slideSpacing={15}
                      singleSlideMaxWidth={width > 500 && 275}
                      withNavigation={perPage !== 1}
                      renderLeftArrow={() => (
                        <FontAwesomeIcon icon={faChevronLeft} />
                      )}
                      renderRightArrow={() => (
                        <FontAwesomeIcon icon={faChevronRight} />
                      )}
                      onNavigateLeftClicked={() => {
                        sendUserActionToGTM(
                          'Click carousel prev',
                          {
                            label: 'OffersCarousel'
                          },
                          customer.id
                        )
                      }}
                      onNavigateRightClicked={() => {
                        sendUserActionToGTM(
                          'Click carousel next',
                          {
                            label: 'OffersCarousel'
                          },
                          customer.id
                        )
                      }}
                      onChangeIndex={(newIndex, normalizedIndex) => {
                        this.setState({
                          currentPage: newIndex,
                          normalizedPage: normalizedIndex
                        })
                        sendUserActionToGTM(
                          'Carousel change index',
                          {
                            label: 'OffersCarousel'
                          },
                          customer.id
                        )
                      }}>
                      {data.customerOffers.map((offer, index) => (
                        <ProductCard
                          theme={THEME_ID}
                          key={`offer_item_${offer.productCode}_${index}`}
                          url={offer.url}
                          title={offer.productTitle}
                          imageUrl={offer.productImageUrl}
                          pricePrefixText="Vain"
                          priceText={offer.pricingText}
                          priceColor="#ed5e69"
                          originalPriceText={offer.originalPricingText}
                          titleDetail={offer.termLength && `${offer.termLength} kk`}
                          buttonText={Text('OFFER_ORDER_CTA')}
                          smallPrintText="Jatkuva tilaus"
                          width="100%"
                          imageFixedAspectRatio={0.8}
                          fontSize={14}
                          buttonBackgroundColor="#ed5e69"
                          visible={isSlideVisible(index)}
                          EE={{
                            id: offer.productCode,
                            trackClick: true,
                            trackImpression: true,
                            list: 'OffersCarousel'
                          }}
                          onClick={() => {
                            sendUserActionToGTM(
                              'Click carousel',
                              {
                                label: 'OffersCarousel',
                                magazineCode: offer.productCode
                              },
                              customer.id
                            )
                          }}>
                          {offer.pullElementText &&
                            offer.pullElementColor && (
                              <LehtitarjouksetRibbonOverlay
                                text={offer.pullElementText}
                                color={offer.pullElementColor}
                              />
                            )}
                        </ProductCard>
                      ))}
                    </BaseCarousel>
                    {pageCount > 1 && (
                      <CirclePagination
                        page={currentPage}
                        pageCount={pageCount}
                        onChangeIndex={(newIndex) => {
                          this.setState({
                            currentPage: newIndex,
                            normalizedPage: newIndex
                          })
                        }}
                      />
                    )}
                  </div>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default withViewerContext(withWindowDimensions(Offers))
